import React from "react";

const Arrow = () => {
    return(
        <div className="d-flex justify-content-center mt-3" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100">
        <a href="#productos"><i className="floating fa-solid fa-chevron-down fa-2xl"
                style={{color: "#bb2d2d"}}></i></a>
    </div>
    )
}
export default Arrow;