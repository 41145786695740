import { useNavigate } from "react-router-dom";
import styles from './Thank-You.module.css'

const ThankYou = () => {
  const navigate = useNavigate();
    return(
      <div className={styles.thank_container}>
        <div className={`${styles.card} card`}>
          <div className={styles.img_container}>
        <img src="https://i.ibb.co/1fG8rg5/logo-finalite.png" className={`${styles.img} card-img-top`} alt="..."/>
          </div>
        <div className="card-body">
          <h5 className="card-title text-center">Mensaje enviado exitosamente!</h5>
          <p className="card-text text-center">Gracias por comunicarte con nosotros. Nuestro equipo se estará poniendo en contacto a la brevedad.</p>
          <div className={styles.button_container}>
          <a href="#" className={`${styles.button} btn btn-primary`} onClick={() => navigate('/')} >Volver al home</a>
          </div>
        </div>
      </div>
      </div>
    )
}

export default ThankYou;