import React from "react";

import styles from "./Card.module.css"

const Card = ({ imageName, text }) => {
    return (
        <div className={`${styles.card_container} card col-lg-5 col-md-5 col-sm-12 m-3 p-0`} data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100">
            <div className={styles.img_div}>
                <img src={imageName} className={`${styles.img} card-img-top`} alt="card" />
            </div>
            <div className="card-body">
                <p className="text-cards card-text">{text}</p>
            </div>
        </div>
    )
}

export default Card;