import Home from "../views/Home/Home";
import ThankYou from "../views/ThankYou/ThankYou";

const routes = [
    {
        path: '/',
        element: <Home/>
    },
    {
        path: '/thank-you',
        element: <ThankYou/>
    }
]

export default routes;