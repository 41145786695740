import React from "react";

const Title = () => {
    return (
        <section className="text-center">
            <h1 data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100" className="pt-2">WE CONTAIN</h1>
            <h2 data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100" >DEPÓSITO GUARDAMUEBLES</h2>
        </section> 
    )
}

export default Title;