import React from "react";
import Navbar from '../../components/Navbar/Navbar.jsx'
import Title from "../../components/Title/Title.jsx";
import Arrow from "../../components/Arrow/Arrow.jsx";
import Card from "../../components/Card/Card.jsx";
import dataCard from "../../components/Card/Data-Card.js";
import About from "../../components/About-Us/About.jsx";
import How from "../../components/How-Works/How.jsx";
import Footerr from "../../components/Footer/Footer-new.jsx";
import NewCarousel from "../../components/Carrousel/New-Carrousel.jsx";
import Banner from "../../components/Banner/Banner.jsx";
import { IoLogoWhatsapp } from "react-icons/io";

function Home() {
  return (
    <>
      <Navbar />
      <Title />
      <div className="container px-4 mt-3">
        <NewCarousel />
        <Banner/>
        <Arrow />
        <div className="d-flex justify-content-center">
          <h5 id="productos" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100" className=" lined"><strong>Cuidamos, Protegemos y Contenemos</strong></h5>
        </div>
        <How />
        <div className="row justify-content-center" >
          {dataCard.map(item => <Card imageName={item.imageName} title={item.title} text={item.text} />)}
        </div>
        <div className="d-flex justify-content-center">
          <h5 id="sobre" className="card-title lined" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100"><strong>Sobre Nosotros</strong></h5>
        </div>
        <About />
      </div>
      <a href="https://api.whatsapp.com/send?phone=541135630564" target="_blank"><IoLogoWhatsapp className="wasap_button" /></a>
      <Footerr />
    </>
  );
}

export default Home;
