import React from "react";

import img from "../../assets/img/we-storage.png"

import styles from "./About.module.css"

const About = () => {
    return (
        <div className="about-us mx-auto mt-5" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100">
            <p className={styles.about_text}>Somos una empresa dedicada al almacenamiento y resguardo de mercaderías y
                bienes personales. Brindamos servicio personalizado en el cual contamos con
                tres tamaños distintos de contenedores: 10 pies, 20 pies y 40 pies (Hc).
                Somos conscientes de lo importante que es vivir con tranquilidad, sabiendo que tus
                pertenencias o la mercaderia de tu EMPRESA o PYME, esta vigilado y
                protegido.
                Nuestro trabajo es CUIDAR. Nuestro trabajo es PROTEGER. Nuestro trabajo es
                asegurar TRANQUILIDAD.</p>
            <div className={styles.div_final}>
                <p className={styles.nos_contenemos}><em><strong>NOSOTROS <br/>CONTENEMOS</strong></em></p>
                <div className={styles.img_container}>
                    <img className={styles.img} src={img} alt="we-storage" />
                </div>
                <p className={styles.we_contain}><em><strong>WE <br/>CONTAIN</strong></em></p>
            </div>
        </div>
    )
}

export default About;