import Image1 from "../../assets/img/foto_one.jpg"
import Image3 from "../../assets/img/foto_dos.jpg"
import Image2 from "../../assets/img/foto_tres.jpg"
import Image4 from "../../assets/img/foto_cuatro.jpg"

const dataCard = [
{
    imageName: Image1,
    text: "Unidades de 10 pies: 2,40 x 2,40 x 3mts con una capacidad total de 15,9 m³."
},
{
    imageName: Image2,
    text: "Unidades de 20 pies: 2,40 x 2,40 x 6mts con una capacidad total de 32,2 m³."
},
{
    imageName: Image3,
    text: "Unidades de 40 pies: 2,40 x 2,40 x 12mts con una capacidad total de 67,7 m³."
},
{
    imageName: Image4,
    text: "Todos los contenedores se encuentran dentro de un predio con seguridad y vigilancia las 24 hs."
}
]

export default dataCard;
