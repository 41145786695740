import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import img1 from "../../assets/img/foto-web-4.jpg";
import img2 from "../../assets/img/foto-web-1.jpg";
import img3 from "../../assets/img/foto-real-4.jpg";

import styles from "./Carrousel.module.css";

const images = [img3, img2, img1];

const NewCarousel = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const loadImages = async () => {
      await Promise.all(images.map(imgSrc => new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imgSrc;
        img.onload = resolve;
        img.onerror = reject;
      })));
      setInitialLoad(false);
    };

    loadImages();

    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={styles.carousel_father} data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100"
      style={{
        position: 'relative',
        borderRadius: '4px',
        maxWidth: '88%',
        minHeight: '60vh',
        overflow: 'hidden',
        backgroundColor: '#eee',
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: '0 0 15px 0 rgba(0,0,0,.315)',
      }}
    >
      <AnimatePresence exitBeforeEnter={false}>
        <motion.img
          key={currentImage}
          src={images[currentImage]}
          alt={`Slide ${currentImage + 1}`}
          className={styles.img_div}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            objectFit: 'cover',
            borderRadius: '4px',
          }}
          initial={{
            opacity: initialLoad ? (currentImage === 0 ? 1 : 0) : 0,
            x: initialLoad ? 0 : '100%',
          }}
          animate={{ opacity: 1, x: '0' }}
          exit={{ x: '-100%' }}
          transition={{
            type: 'tween',
            duration: 0.8,
            ease: 'easeInOut',
          }}
        />
      </AnimatePresence>
    </div>
  );
};

export default NewCarousel;
