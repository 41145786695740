import styles from "./Banner.module.css";

const Banner = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.banner_text}>
      &nbsp;&nbsp;&nbsp;&nbsp; ALQUILER DE ESPACIO EN CONTENEDORES &nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    </div>
  );
};

export default Banner;
