import React from "react";

const How = () => {
    return (
        <div className="como mx-auto mb-1" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100">
            <p className="practice">Ofrecemos la solución más práctica y confortable para almacenar tus
pertenencias y/o mercaderías. Seguridad y cámaras las 24hs garantizando
protección y resguardo en nuestra fortaleza. Contamos con tres tamaños
distintos de <strong>CONTENEDORES</strong> para ajustarnos a tu necesidad empresarial o
personal. Nos ocupamos de tu tranquilidad.</p>
            <h5 className="text-center mt-4 mb-5" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100"><strong>¿CÓMO FUNCIONA?</strong></h5>
            <div className="row mt-4">
                <div className="d-flex flex-column justify-content-center align-content-center col-lg-4 col-md-4 col-sm-12 mt-3" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100">
                    <i className="fa-solid fa-phone-volume fa-2xl text-center mb-3" style={{ color: "#BB2D2D" }}></i>
                    <p className="practice text-center"><strong>1.</strong> Entra en contacto con nosotros vía telefónica o por email. Brindamos atención personalizada</p>
                </div>
                <div className="d-flex flex-column justify-content-center align-content-center col-lg-4 col-md-4 col-sm-12 mt-3" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100">
                    <i className="fa-solid fa-calendar-days fa-2xl text-center mb-3" style={{ color: "#BB2D2D" }}></i>
                    <p className="practice text-center"><strong>2.</strong> Coordinamos un encuentro en el predio para que te designemos tu
contenedor</p>
                </div>
                <div className="d-flex flex-column justify-content-center align-content-center col-lg-4 col-md-4 col-sm-12 mt-3" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100">
                    <i className="fa-solid fa-key fa-2xl text-center mb-3" style={{ color: "#BB2D2D" }}></i>
                    <p className="practice text-center"><strong>3.</strong> Se firma un contrato y te entregamos tu llave para que cierres el candado
del contenedor designado</p>
                </div>
            </div>
        </div>
    )
}

export default How;