import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";

import styles from "./Footer-new.module.css"
import cool from "./../../assets/img/logo-footer.png";

const Footerr = () => {

    const form = useRef();
    const navigate = useNavigate();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_d4s747i', 'template_nfuxhvq', form.current, 'pY_4-aRSCLLG8DX0Z')
            .then((result) => {
                console.log(result.text);
                form.current.reset();
                navigate('/thank-you')
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <footer className="d-flex justify-content-center" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1100">
            <div className="footer-jr container">
                <h5 id="contacto" className="p-contacto text-center my-4 pt-4"><strong>Contacto</strong></h5>
                <div className="ulises d-flex row">
                    <div className={styles.info_container}>
                        <div className={styles.icons_container}>
                            <div className={styles.location}>
                                <a href="https://www.google.com/maps?ll=-34.458171,-58.704164&z=15&t=m&hl=es-419&gl=AR&mapclient=embed&q=34%C2%B027%2729.4%22S+58%C2%B042%2705.7%22W+-34.458167,+-58.701583@-34.4581667,-58.7015833" target="_blank"><i className="iconitos fa-sharp fa-solid fa-location-dot fa-xl my-4" ></i></a>
                                <p className={`${styles.p_container} c-one`} p-contacto mb-0 w-100>Leonardo Euler 2575, Malvinas Argentinas</p>
                            </div>
                            <div className={styles.whatsapp}>
                                <a href="https://api.whatsapp.com/send?phone=541135630564" target="_blank"><i className="iconitos fa-brands fa-whatsapp fa-xl"></i></a>
                                <p className={`${styles.p_container} c-two`} p-contacto mb-0 w-100>11-3563-0564<br />11-3688-2418<br />11-3686-9512</p>
                            </div>
                            <div className={styles.mail}>
                                <a href="mailto:wecontainargentina@gmail.com"><i className="iconitos fa-regular fa-envelope fa-xl my-4" ></i></a>
                                <p className={`${styles.p_container} c-three`} p-contacto mb-0 w-100>wecontainargentina@gmail.com</p>
                            </div>
                            <div className={styles.instagram}>
                                <a className=" text-decoration-none"
                                    href="https://www.instagram.com/we_contain/?hl=es-la" target="_blank"><i className="iconitos fa-brands fa-instagram fa-xl" ></i></a>
                                <p className={`${styles.p_container} c-four p-contacto  mb-0 w-100`}>@we_contain</p>
                            </div>

                        </div>
                        <form ref={form} onSubmit={sendEmail} className={styles.form}>
                            <input type="text" name="user_name" className={styles.input_holder} required placeholder="Nombre" />
                            <input type="email" name="user_email" className={styles.input_holder} required placeholder="Email" />
                            <input type="number" name="user_number" className={styles.input_holder} required placeholder="Teléfono" />
                            <textarea name="message" className={styles.message_holder} required placeholder="Mensaje" />
                            <input type="submit" className={styles.send_button} value="Enviar" />
                        </form>
                    </div>
                    <div className="little-bro">
                        <iframe className="g-map mb-5" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3289.762708153663!2d-58.704163924266005!3d-34.45817097300681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDI3JzI5LjQiUyA1OMKwNDInMDUuNyJX!5e0!3m2!1ses-419!2sar!4v1691503567882!5m2!1ses-419!2sar" width="600" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        <p className="p-contactt mt-3 text-center">We Contain © 2023. Todos los derechos reservados. <br /> www.wecontain.com.ar </p>
                        <a className="father-foot" href="https://www.instagram.com/we_contain/?hl=es-la"><img
                            className="w-100 father-foot" src={cool} alt="logo" /></a>
                    </div>
                </div>
            </div>
        </footer >
    )
}
export default Footerr;