import { BrowserRouter, Routes, Route } from 'react-router-dom';
import routes from '../src/routes/routes'
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

function App() {

  return (
    <BrowserRouter basename='/'>
      <Routes>
        {routes.map((route) => <Route {...route} key={route.path} />)}
      </Routes>
    </BrowserRouter>
  )
}

export default App;